import axios from "axios";
import authHeader from "./auth-header";

const VUE_APP_BASE_URL = process.env.VUE_APP_BASE_URL;

class ReportService {
  sales(formData) {
    return axios.post(VUE_APP_BASE_URL + "sales-report", formData, {
      headers: authHeader(),
    });
  }
  salesAnalysis(formData) {
    return axios.post(VUE_APP_BASE_URL + "sales-analysis", formData, {
      headers: authHeader(),
    });
  }
  salesReportOrderwise(formData) {
    return axios.post(VUE_APP_BASE_URL + "sales-report-orderwise", formData, {
      headers: authHeader(),
    });
  }
  dailyAnalysis() {
    return axios.get(VUE_APP_BASE_URL + "daily-analysis", {
      headers: authHeader(),
    });
  }
  weeklyAnalysis() {
    return axios.get(VUE_APP_BASE_URL + "weekly-analysis", {
      headers: authHeader(),
    });
  }
  paymentAnalysis(formData) {
    return axios.post(VUE_APP_BASE_URL + "payment-analysis", formData, {
      headers: authHeader(),
    });
  }
  stockAnalysis() {
    return axios.get(VUE_APP_BASE_URL + "stock-analysis", {
      headers: authHeader(),
    });
  }
  bestSeller() {
    return axios.get(VUE_APP_BASE_URL + "best-seller", {
      headers: authHeader(),
    });
  }
  
}

export default new ReportService();
