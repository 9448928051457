import { createRouter, createWebHashHistory } from "vue-router";
import Login from "../views/LoginView.vue";
import PosView from "../views/PosView.vue";
import CartView from "../views/CartView.vue";
import InvoiceView from "../views/InvoiceView.vue";
import ReservedRoomsView from "../views/ReservedRoomsView.vue";
import BlankView from "../views/BlankView.vue";
import InvoicesView from "../views/InvoicesView.vue";
import ReservationReportView from "../views/ReservationReportView.vue";
import UsersView from "../views/UsersView.vue";
import StoreView from "../views/StoreView.vue";
import RoomsView from "../views/RoomsView.vue";
import RolesView from "../views/RolesView.vue";
import RightsView from "../views/RightView.vue";
import PrinterView from "../views/PrinterView.vue";
import ModulesView from "../views/ModulesView.vue";
import MenuItemView from "../views/MenuItemView.vue";
import MenuGroupView from "../views/MenuGroupView.vue";
import TerminalsView from "../views/TerminalsView.vue";
import CustomersView from "../views/CustomersView.vue";
import DashboardView from "../views/DashboardView.vue";
import OpenOrdersView from "../views/OpenOrdersView.vue";
import StockSheetView from "../views/StockSheetView.vue";
import OrderTypesView from "../views/OrderTypesView.vue";
import RoomsReportView from "../views/RoomsReportView.vue";
import SalesReportView from "../views/SalesReportView.vue";
import MenuItemSizeView from "../views/MenuItemSizeView.vue";
import RightView from "../views/RightView.vue";
import MenuCategoryView from "../views/MenuCategoryView.vue";
import MenuEasyEditView from "../views/MenuEasyEditView.vue";
import ConfigurationView from "../views/ConfigurationView.vue";
import InventoryUnitView from "../views/InventoryUnitView.vue";
import ResetPasswordView from "../views/ResetPasswordView.vue";
import InventoryItemView from "../views/InventoryItemView.vue";
import NotificationsView from "../views/NotificationsView.vue";
import ForgotPasswordView from "../views/ForgotPasswordView.vue";
import InventoryGroupsView from "../views/InventoryGroupsView.vue";
import PaymentAnalysisView from "../views/PaymentAnalysisView.vue";
import MenuItemBarcodesView from "../views/MenuItemBarcodesView.vue";
import StockSheetReportView from "../views/StockSheetReportView.vue";
import InventoryLocationView from "../views/InventoryLocationView.vue";
import InventoryWarehouseView from "../views/InventoryWarehouseView.vue";
import InventoryDashboardView from "../views/InventoryDashboardView.vue";
import SalesReportOrderwiseView from "../views/SalesReportOrderwiseView.vue";
import TicketAnalysisReportView from "../views/TicketAnalysisReportView.vue";
import KitchenViewVue from "@/views/KitchenView.vue";

const routes = [
  {
    path: "/",
    name: "init",
    component: Login,
  },
  {
    path: "/configuration",
    name: "configuration",
    component: ConfigurationView,
    meta: { layout: "main" },
  },
  {
    path: "/inventory-warehouse",
    name: "inventory-warehouse",
    component: InventoryWarehouseView,
    meta: { layout: "main" },
  },
  {
    path: "/invoice/:id?",
    name: "invoice",
    component: InvoiceView,
    meta: { layout: "main" },
  },

  {
    path: "/barcode",
    name: "barcode",
    component: MenuItemBarcodesView,
    meta: { layout: "main" },
  },
  {
    path: "/stock-sheet-report",
    name: "stock-sheet-report",
    component: StockSheetView,
    meta: { layout: "main" },
  },
  {
    path: "/stock-sheet/:id?",
    name: "stock-sheet",
    component: StockSheetReportView,
    meta: { layout: "main" },
  },

  {
    path: "/order-type",
    name: "order-type",
    component: OrderTypesView,
    meta: { layout: "main" },
  },

  {
    path: "/inventory_unit.index",
    name: "inventory_unit.index",
    component: InventoryUnitView,
    meta: { layout: "main" },
  },
  {
    path: "/printer.index",
    name: "printer.index",
    component: PrinterView,
    meta: { layout: "main" },
  },
  {
    path: "/store",
    name: "store",
    component: StoreView,
    meta: { layout: "main" },
  },
  {
    path: "/pos",
    name: "pos",
    component: PosView,
    meta: { layout: "main" },
  },
  {
    path: "/rooms",
    name: "Rooms",
    component: RoomsView,
    meta: { layout: "main" },
  },
  {
    path: "/inventory-items",
    name: "inventory-items",
    component: InventoryItemView,
    meta: { layout: "main" },
  },

  {
    path: "/rooms",
    name: "Rooms",
    component: RoomsView,
    meta: { layout: "main" },
  },
  {
    path: "/notifications",
    name: "notifications",
    component: NotificationsView,
    meta: { layout: "main" },
  },

  {
    path: "/forgot-password",
    name: "forgot-password",
    component: ForgotPasswordView,
    meta: { layout: "main" },
  },
  {
    path: "/reset-password/:token",
    name: "reset-password",
    component: ResetPasswordView,
    meta: { layout: "main" },
  },

  {
    path: "/customer.index",
    name: "customer.index",
    component: CustomersView,
    meta: { layout: "main" },
  },
  {
    path: "/cart",
    name: "cart",
    component: CartView,
    meta: { layout: "main" },
  },

  {
    path: "/menu_category.index",
    name: "menu_category.index",
    component: MenuCategoryView,
    meta: { layout: "main" },
  },
  {
    path: "/menu_group.index",
    name: "menu_group.index",
    component: MenuGroupView,
    meta: { layout: "main" },
  },
  {
    path: "/menu_item_size.index",
    name: "menu_item_size.index",
    component: MenuItemSizeView,
    meta: { layout: "main" },
  },
  {
    path: "/right.index",
    name: "right.index",
    component: RightView,
    meta: { layout: "main" },
  },
  {
    path: "/inventory-location",
    name: "inventory-location",
    component: InventoryLocationView,
    meta: { layout: "main" },
  },

  {
    path: "/menu-item",
    name: "menu-item",
    component: MenuItemView,
    meta: { layout: "main" },
  },
  {
    path: "/invoice-report",
    name: "invoice-report",
    component: InvoicesView,
    meta: { layout: "main" },
  },
  {
    path: "/menu-easy-edit",
    name: "menu-easy-edit",
    component: MenuEasyEditView,
    meta: { layout: "main" },
  },

  {
    path: "/daily-sales-report-by-order",
    name: "daily-sales-report-by-order",
    component: RoomsReportView,
    meta: { layout: "main" },
  },
  {
    path: "/reservation",
    name: "reservation",
    component: ReservedRoomsView,
    meta: { layout: "main" },
  },
  {
    path: "/reservation-report",
    name: "reservation-report",
    component: ReservationReportView,
    meta: { layout: "main" },
  },

  {
    path: "/inventory.index",
    name: "inventory-dash",
    component: InventoryDashboardView,
    meta: { layout: "main" },
  },
  {
    path: "/inventory-group",
    name: "inventory-group",
    component: InventoryGroupsView,
    meta: { layout: "main" },
  },

  {
    path: "/home",
    name: "home",
    component: PosView,
    meta: { layout: "main" },
  },
  {
    path: "/terminal.index",
    name: "terminal.index",
    component: TerminalsView,
    meta: { layout: "main" },
  },

  {
    path: "/blank",
    name: "blank",
    component: BlankView,
    meta: { layout: "main" },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/module.index",
    name: "module.index",
    component: ModulesView,
    meta: { layout: "main" },
  },
  {
    path: "/user.index",
    name: "user.index",
    component: UsersView,
    meta: { layout: "main" },
  },
  {
    path: "/pos-open-order",
    name: "pos-open-order",
    component: OpenOrdersView,
    meta: { layout: "main" },
  },
  {
    path: "/kitchen.index",
    name: "kitchen.index",
    component: KitchenViewVue,
    meta: { layout: "main" },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: DashboardView,
    meta: { layout: "main" },
  },
  {
    path: "/role.index",
    name: "role",
    component: RolesView,
    meta: { layout: "main" },
  },
  {
    path: "/sales-analysis-report",
    name: "sales-analysis-report",
    component: SalesReportView,
    meta: { layout: "main" },
  },
  {
    path: "/ticket-analysis",
    name: "ticket_analysis",
    component: TicketAnalysisReportView,
    meta: { layout: "main" },
  },
  {
    path: "/payment-analysis-report",
    name: "payment_analysis_report",
    component: PaymentAnalysisView,
    meta: { layout: "main" },
  },
];

const router = createRouter({
  mode: "history",
  mode: "hash", // https://router.vuejs.org/api/#mode
  linkActiveClass: "active",
  base: process.env.VUE_APP_ROOT_URL,
  history: createWebHashHistory(process.env.VUE_APP_ROOT_URL),
  routes: routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 };
  },
});

// control auth in all routes
router.beforeEach((to, from, next) => {
  const publicPages = [
    "/login",
    "/register/enterprise",
    "/register/individual",
    "/register/starter",
    "/register/standard",
    "/home",
  ];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("token");
  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next("/login");
  } else {
    next();
  }
});

export default router;
