// Data sevice
import axios from "axios";
import authHeader from "./auth-header";

const VUE_APP_BASE_URL = process.env.VUE_APP_BASE_URL;

class MenuItemsService {
  all(order_type){
    return axios.get(VUE_APP_BASE_URL + "menu-item-all/"+order_type, { headers: authHeader() });
  }
  index(page) {
    return axios.get(VUE_APP_BASE_URL + "menu-item?page="+page, { headers: authHeader() });
  }
  byOrder(page, order_type) {
    return axios.get(VUE_APP_BASE_URL + "menu-item-byorder/"+order_type+"?page="+page, { headers: authHeader() });
  }
  menuList(formData, page) {
    return axios.post(VUE_APP_BASE_URL + "menu-item-list?page="+page, formData, {
      headers: authHeader(),
    });
  }
  menuSearch(formData, page) {
    return axios.post(VUE_APP_BASE_URL + "menu-item-search?page="+page, formData, {
      headers: authHeader(),
    });
  }
  inventoryTrans() {
    return axios.get(VUE_APP_BASE_URL + "menu-item-transactions", {
      headers: authHeader(),
    });
  }
  store(item, config) {
    return axios.post(VUE_APP_BASE_URL + "menu-item", item, config);
  }
  update(item, config) {
    return axios.put(VUE_APP_BASE_URL + "menu-item/" + item.ID, item, config);
  }
  update2(item, config) {
    return axios.post(VUE_APP_BASE_URL + "menu-item-update", item, config);
  }
  delete(item) {
    return axios.delete(VUE_APP_BASE_URL + "menu-item/" + item.ID, {
      headers: authHeader(),
    });
  }
  reduce(item) {
    return axios.post(VUE_APP_BASE_URL + "menu-item-reduce", item, {
      headers: authHeader(),
    });
  }
  import(json_data) {
    return axios.post(VUE_APP_BASE_URL + "menu-item-import", json_data, {
      headers: authHeader(),
    });
  }
  restock(item) {
    return axios.post(VUE_APP_BASE_URL + "menu-item-restock", item, {
      headers: authHeader(),
    });
  }
  transfer(item) {
    return axios.post(VUE_APP_BASE_URL + "menu-item-transfer", item, {
      headers: authHeader(),
    });
  }
}

export default new MenuItemsService();
