<template>
  <div>
    <div
      class="card mb-3"
      id="ordersTable"
      data-list='{"valueNames":["order","date","address","status","amount"],"page":10,"pagination":true}'
    >
      <div class="card-header border-bottom py-2 mb-1">
        <div class="row flex-between-center">
          <div class="col-4 col-sm-auto d-flex align-items-center pe-0">
            <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">Room Reservations</h5>
          </div>
          <div class="col-8 col-sm-auto ms-auto text-end ps-0">
            <div class="d-none" id="orders-bulk-actions">
              <div class="d-flex"></div>
            </div>
            <div id="orders-action" class="d-flex">
              <!-- <button
                class="btn btn-sm btn-falcon-success mx-1 shrink-3"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#add-modal"
              >
                <span
                  class="fas fa-plus"
                  data-fa-transform="shrink-3 down-2"
                ></span
                ><span class="d-none d-sm-inline-block ms-1">New</span>
              </button> -->
              <button
                class="btn btn-sm btn-falcon-success mx-1 shrink-3"
                type="button"
                @click="$router.push('/rooms')"
              >
                <span class="fas fa-home" data-fa-transform="shrink-3 down-2"></span
                ><span class="d-none d-sm-inline-block ms-1">Rooms</span>
              </button>
              <button
                class="btn btn-sm btn-falcon-info mx-1 shrink-3"
                type="button"
                @click="goToReservationReport"
              >
                <span class="fas fa-calendar" data-fa-transform="shrink-3 down-2"></span
                ><span class="d-none d-sm-inline-block ms-1">Rooms Report</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body px-3 py-1">
        <div class="search-box h1" style="width: 250px">
          <input
            class="form-control search-input fuzzy-search ms-2"
            type="text"
            placeholder="Search..."
            aria-label="Search"
            v-model="searchValue"
            style="height: 32px"
          />
          <span class="fas fa-search search-box-icon"></span>
        </div>
        <!-- Menu items table  -->
        <EasyDataTable
          id="tour-step-1"
          class="mx-2 my-2"
          table-class-name="customize-table"
          alternating
          buttons-pagination
          :headers="headers"
          :items="items"
          :search-field="searchField"
          :search-value="searchValue"
          :loading="loading"
        >
          <template #loading>
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </template>
          <template #item-id="item">
            <div class="form-check fs-0 mb-0 d-flex align-items-center">
              <input
                class="form-check-input"
                type="checkbox"
                id="checkbox-0"
                :value="item.id"
                @click="updateSelected"
                data-bulk-select-row="data-bulk-select-row"
              />
              <a class="text-secondary ms-2">
                #<strong class="ml-1">{{ item.id }}</strong></a
              >
            </div>
          </template>
          <template #item-room="item">
            <strong>{{ item.room ? item.room.title : "" }}</strong>
            <p class="mb-0 text-500 text-wrap">
              No. {{ item.room ? item.room.room_no : "" }} Price: Ksh
              {{ item.room ? item.room.price : "" }}
            </p>
          </template>
          <template #item-details="item">
            <p class="mb-0 text-500 text-wrap">
              {{ item.details }}
            </p>
          </template>
          <template #item-action="item">
            <button class="btn btn-sm btn-falcon-primary" role="button" @click="checkout(item)">
            <i class="fa fa-check"></i>  Checkout
            </button>
            <!-- <div class="dropdown font-sans-serif position-static">
              <button
                class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal"
                type="button"
                id="order-dropdown-0"
                data-bs-toggle="dropdown"
                data-boundary="viewport"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span class="fas fa-ellipsis-h fs--1"></span>
              </button>
              <div
                class="dropdown-menu dropdown-menu-end border py-0"
                aria-labelledby="order-dropdown-0"
              >
                <div class="py-2">
                  <a
                    class="dropdown-item text-info"
                    role="button"
                    href="javascript:;"
                    @click="printReceipt"
                    >Print Receipt</a
                  >
                  <div class="dropdown-divider"></div>
                  <a
                    class="dropdown-item text-danger"
                    role="button"
                    href="javascript:;"
                    @click="checkout(item)"
                    >Checkout</a
                  >
                </div>
              </div>
            </div> -->
          </template>
          <template #item-status="item">
            <span
              v-if="item.status"
              class="badge badge rounded-pill badge-soft-secondary py-2"
              >Open
              <span class="ms-1 fas fa-check" data-fa-transform="shrink-2"> </span>
            </span>
            <span
              v-if="!item.status"
              class="badge badge rounded-pill badge-soft-danger py-2"
            >
              Closed
              <span class="ms-1 fas fa-times" data-fa-transform="shrink-2"> </span>
            </span>
          </template>
          <template #item-door_enabled="item">
            <label class="switch">
              <input
                type="checkbox"
                :checked="item.door_enabled"
                @change="toogleEnable(item)"
              />
              <span class="slider round"></span>
            </label>
          </template>
        </EasyDataTable>
        <!-- End Menu items table  -->
      </div>
      <div class="card-footer"></div>
    </div>
    <div v-for="(resource, i) in resources" :key="i">
      <!-- Start edit modal  -->
      <div
        class="modal fade"
        :id="'edit-modal' + resource.id"
        data-bs-keyboard="false"
        data-bs-backdrop="static"
        tabindex="-1"
        :aria-labelledby="'edit-modal' + resource.id + 'Label'"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg mt-6" role="document">
          <div class="modal-content border-0">
            <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
              <button
                class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body p-0">
              <div class="bg-light rounded-top-lg py-3 ps-4 pe-6">
                <h4 class="mb-1" id="staticBackdropLabel">Edit module</h4>
              </div>
              <div class="p-4">
                <div class="row">
                  <div class="col-sm-12 col-md-12 col-lg-9 col-xl-9">
                    <div class="d-flex">
                      <span class="fa-stack ms-n1 me-3"
                        ><i class="fas fa-circle fa-stack-2x text-200"></i
                        ><i
                          class="fa-inverse fa-stack-1x text-primary fas fa-tag"
                          data-fa-transform="shrink-2"
                        ></i
                      ></span>
                      <div class="flex-1">
                        <div class="row">
                          <div class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                            <label class="col-form-label" for="recipient-name"
                              >Name:</label
                            >
                            <input
                              class="form-control"
                              id="recipient-name"
                              type="text"
                              :placeholder="resource.title"
                              v-model="formData.title"
                              :class="
                                v$.formData.title.$dirty && v$.formData.title.$invalid
                                  ? `is-invalid`
                                  : v$.formData.title.$dirty
                                  ? `is-valid`
                                  : ``
                              "
                            />
                            <div
                              v-for="error of v$.formData.title.$errors"
                              :key="error.$uid"
                              class="invalid-feedback"
                              style="color: red"
                            >
                              This field is invalid
                            </div>
                          </div>
                          <div class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                            <label class="col-form-label" for="message-text">Icon:</label>
                            <input
                              class="form-control"
                              id="recipient-name"
                              type="text"
                              :placeholder="resource.icon"
                              v-model="formData.icon"
                            />
                          </div>
                          <div class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                            <label class="col-form-label" for="message-text"
                              >Color:</label
                            >
                            <input
                              class="form-control"
                              id="recipient-name"
                              type="text"
                              :placeholder="resource.color"
                              v-model="formData.color"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                            <label class="col-form-label" for="recipient-name"
                              >Order:</label
                            >
                            <input
                              class="form-control"
                              id="recipient-name"
                              type="text"
                              :placeholder="resource.order"
                              v-model="formData.order"
                            />
                          </div>
                          <div class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                            <label class="col-form-label" for="message-text"
                              >Route:</label
                            >
                            <input
                              class="form-control"
                              id="recipient-name"
                              type="text"
                              :placeholder="resource.link"
                              v-model="formData.link"
                            />
                          </div>
                          <div class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                            <label class="col-form-label" for="message-text"
                              >Select parent?</label
                            >
                            <select
                              class="form-select"
                              placeholder="Select parent"
                              v-model="formData.id_parent"
                            >
                              <option
                                v-for="mod in resources"
                                :key="mod.id"
                                :value="mod.id"
                                :selected="mod.id == resource.id_parent"
                              >
                                {{ mod.title }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <hr class="my-4" />
                      </div>
                    </div>
                    <div class="d-flex">
                      <span class="fa-stack ms-n1 me-3"
                        ><i class="fas fa-circle fa-stack-2x text-200"></i
                        ><i
                          class="fa-inverse fa-stack-1x text-primary fas fa-align-left"
                          data-fa-transform="shrink-2"
                        ></i
                      ></span>
                      <div class="flex-1">
                        <h5 class="mb-2 fs-0">Description</h5>

                        <p class="text-word-break fs--1">
                          <textarea
                            v-model="formData.detail"
                            class="form-control"
                            id="exampleFormControlTextarea1"
                            rows="3"
                            :placeholder="formData.detail"
                          ></textarea>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                    <h6 class="mt-5 mt-lg-0">Other Actions</h6>
                    <ul class="nav flex-lg-column fs--1">
                      <li class="nav-item me-2 me-lg-0">
                        <a
                          @click="toggleActive"
                          class="nav-link nav-link-card-details"
                          role="button"
                          :class="
                            formData.active || resource.active
                              ? `bg-success text-white`
                              : ``
                          "
                          ><span class="fa fa-check me-2"></span>Active?
                        </a>
                      </li>
                      <li class="nav-item me-2 me-lg-0">
                        <a
                          @click="toggleRoot"
                          class="nav-link nav-link-card-details"
                          role="button"
                          :class="
                            formData.is_root || resource.is_root
                              ? `bg-success text-white`
                              : ``
                          "
                        >
                          <span class="fas fa-user me-2"></span>Root/Parent?
                        </a>
                      </li>
                      <li class="nav-item me-2 me-lg-0">
                        <a
                          class="nav-link nav-link-card-details"
                          role="button"
                          @click="toggleShowOnMain"
                          :class="
                            formData.show_on_main_page || resource.show_on_main_page
                              ? `bg-success text-white`
                              : ``
                          "
                          ><span class="fas fa-align-left me-2"></span>Show on Drawer</a
                        >
                      </li>
                      <li class="nav-item me-2 me-lg-0 mt-8">
                        <a
                          class="nav-link nav-link-card-details bg-danger text-white"
                          role="button"
                          @click="deleteRecource(resource)"
                          ><span class="fas fa-paperclip me-2"></span>Delete</a
                        >
                      </li>
                      <li class="nav-item me-2 me-lg-0 mt-2">
                        <a
                          class="nav-link nav-link-card-details bg-primary text-white"
                          role="button"
                          @click="updateResource(resource)"
                          ><span class="fa fa-align-left me-2"></span>Update
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End edit modal  -->
    </div>
    <!-- Start add modal  -->
    <div
      class="modal fade"
      id="add-modal"
      data-bs-keyboard="false"
      data-bs-backdrop="static"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg mt-6" role="document">
        <div class="modal-content border-0">
          <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
            <button
              class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body p-0">
            <div class="bg-light rounded-top-lg py-3 ps-4 pe-6">
              <h4 class="mb-1" id="staticBackdropLabel">Add new module</h4>
            </div>
            <div class="p-4">
              <div class="row">
                <div class="col-xs-12 col-sm-12 col-lg-9 col-xl-9">
                  <div class="d-flex">
                    <span class="fa-stack ms-n1 me-3"
                      ><i class="fas fa-circle fa-stack-2x text-200"></i
                      ><i
                        class="fa-inverse fa-stack-1x text-primary fas fa-tag"
                        data-fa-transform="shrink-2"
                      ></i
                    ></span>
                    <div class="flex-1">
                      <div class="row">
                        <div class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                          <label class="col-form-label" for="recipient-name">Name:</label>
                          <input
                            class="form-control"
                            id="recipient-name"
                            type="text"
                            placeholder="e.g Dashboard"
                            v-model="formData.title"
                            :class="
                              v$.formData.title.$dirty && v$.formData.title.$invalid
                                ? `is-invalid`
                                : v$.formData.title.$dirty
                                ? `is-valid`
                                : ``
                            "
                          />
                          <div
                            v-for="error of v$.formData.title.$errors"
                            :key="error.$uid"
                            class="invalid-feedback"
                            style="color: red"
                          >
                            This field is invalid
                          </div>
                        </div>
                        <div class="mb3 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                          <label class="col-form-label" for="message-text">Icon:</label>
                          <input
                            class="form-control"
                            id="recipient-name"
                            type="text"
                            placeholder="e.g fa fa-user"
                            v-model="formData.icon"
                          />
                        </div>
                        <div class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                          <label class="col-form-label" for="message-text">Color:</label>
                          <input
                            class="form-control"
                            id="recipient-name"
                            type="text"
                            placeholder="e.g #FFFF"
                            v-model="formData.color"
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                          <label class="col-form-label" for="recipient-name"
                            >Order:</label
                          >
                          <input
                            class="form-control"
                            id="recipient-name"
                            type="text"
                            placeholder="e.g 777"
                            v-model="formData.order"
                          />
                        </div>
                        <div class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                          <label class="col-form-label" for="message-text">Route:</label>
                          <input
                            class="form-control"
                            id="recipient-name"
                            type="text"
                            placeholder="e.g /kitchen"
                            v-model="formData.link"
                            :class="
                              v$.formData.link.$dirty && v$.formData.link.$invalid
                                ? `is-invalid`
                                : v$.formData.link.$dirty
                                ? `is-valid`
                                : ``
                            "
                          />
                          <div
                            v-for="error of v$.formData.link.$errors"
                            :key="error.$uid"
                            class="invalid-feedback"
                            style="color: red"
                          >
                            This field is invalid
                          </div>
                        </div>
                        <div class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                          <label class="col-form-label" for="message-text"
                            >Select parent?</label
                          >
                          <select
                            class="form-select"
                            placeholder="Select parent"
                            v-model="formData.id_parent"
                          >
                            <option
                              v-for="mod in resources"
                              :key="mod.id"
                              :value="mod.id"
                              :selected="mod.id == formData.id_parent"
                            >
                              {{ mod.title }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <hr class="my-4" />
                    </div>
                  </div>
                  <div class="d-flex">
                    <span class="fa-stack ms-n1 me-3"
                      ><i class="fas fa-circle fa-stack-2x text-200"></i
                      ><i
                        class="fa-inverse fa-stack-1x text-primary fas fa-align-left"
                        data-fa-transform="shrink-2"
                      ></i
                    ></span>
                    <div class="flex-1">
                      <h5 class="mb-2 fs-0">Description</h5>

                      <p class="text-word-break fs--1">
                        <textarea
                          v-model="formData.detail"
                          class="form-control"
                          id="exampleFormControlTextarea1"
                          rows="3"
                          placeholder="Add details here..."
                        ></textarea>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                  <h6 class="mt-5 mt-lg-0">Other Actions</h6>
                  <ul class="nav flex-lg-column fs--1">
                    <li class="nav-item me-2 me-lg-0">
                      <a
                        @click="toggleActive"
                        class="nav-link nav-link-card-details"
                        role="button"
                        :class="formData.active ? `bg-info text-white` : ``"
                        ><span class="fa fa-check me-2"></span>Active?
                      </a>
                    </li>
                    <li class="nav-item me-2 me-lg-0">
                      <a
                        @click="toggleRoot"
                        class="nav-link nav-link-card-details"
                        role="button"
                        :class="formData.is_root ? `bg-info text-white` : ``"
                      >
                        <span class="fas fa-user me-2"></span>Root/Parent?
                      </a>
                    </li>
                    <li class="nav-item me-2 me-lg-0">
                      <a
                        @click="toggleShowOnMain"
                        class="nav-link nav-link-card-details"
                        role="button"
                        :class="formData.show_on_main_page ? `bg-info text-white` : ``"
                        ><span class="fas fa-align-left me-2"></span>Show on Drawer</a
                      >
                    </li>
                    <br />
                    <hr />
                    <li class="nav-item me-2 me-lg-0 mt-auto">
                      <a
                        class="nav-link nav-link-card-details bg-success text-white"
                        role="button"
                        @click="saveResource"
                        ><span class="fa fa-check me-2"></span>Save
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End add modal  -->

    <div
      class="modal fade"
      id="checkin-modal"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        role="document"
        style="max-width: 500px"
      >
        <div class="modal-content position-relative">
          <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
            <button
              class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body p-0">
            <div class="rounded-top-lg py-3 ps-4 pe-6 bg-light">
              <h4 class="mb-1" id="modalExampleDemoLabel">New Reservation</h4>
            </div>
            <div class="row p-4">
              <div class="col-12">
                <label class="col-form-label" for="message-text">Select Room:</label>
                <select
                  class="form-select"
                  id="roomReservationInput"
                  v-model="editedItem.room_id"
                  @change="setRoomIPAddress"
                  size="1"
                  name="organizerSingle"
                  data-options='{"removeItemButton":true,"placeholder":true}'
                >
                  <option value="">Select room...</option>
                  <option v-for="(item, i) in availableRooms" :key="i" :value="item.id">
                    {{ item.title }}-Ksh {{ item.price }}
                  </option>
                </select>
              </div>
              <div class="col-12">
                <label class="col-form-label" for="message-text">Customer Details:</label>
                <textarea
                  class="form-control"
                  id="message-text"
                  rows="6"
                  v-model="editedItem.details"
                ></textarea>
              </div>
              <div class="col-6 mt-3">
                <form class="row align-items-center g-2">
                  <div class="col-auto">
                    <h6 class="text-700 mb-0">From:</h6>
                  </div>
                  <div class="col-md-auto position-relativ">
                    <flat-pickr
                      v-model="editedItem.from"
                      id="CRMDateRange"
                      class="form-control form-control-sm datetimepicker"
                      style="max-width: 130px"
                      placeholder="Select Start"
                    />
                  </div>
                </form>
              </div>
              <div class="col-6 mt-3">
                <form class="row align-items-center g-2">
                  <div class="col-auto">
                    <h6 class="text-700 mb-0">To:</h6>
                  </div>
                  <div class="col-md-auto position-relative">
                    <flat-pickr
                      v-model="editedItem.to"
                      id="CRMDateRange"
                      class="form-control form-control-sm datetimepicker ps-4"
                      style="max-width: 130px"
                      placeholder="Select Start"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-secondary" type="button" data-bs-dismiss="modal">
              Close
            </button>
            <button class="btn btn-primary" type="button" @click="saveReservation">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="checkout-modal"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        role="document"
        style="max-width: 500px"
      >
        <div class="modal-content position-relative">
          <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
            <button
              class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body p-0">
            <div class="rounded-top-lg py-3 ps-4 pe-6 bg-light">
              <h4 class="mb-1" id="modalExampleDemoLabel">New Reservation</h4>
            </div>
            <div class="row p-4">
              <div class="col-12">
                <label class="col-form-label" for="message-text">Select Room:</label>
                <select
                  class="form-select"
                  id="roomReservationInput"
                  v-model="editedItem.room_id"
                  @change="setRoomIPAddress"
                  size="1"
                  name="organizerSingle"
                  data-options='{"removeItemButton":true,"placeholder":true}'
                >
                  <option value="">Select room...</option>
                  <option v-for="(item, i) in availableRooms" :key="i" :value="item.id">
                    {{ item.title }}-Ksh {{ item.price }}
                  </option>
                </select>
              </div>
              <div class="col-12">
                <label class="col-form-label" for="message-text">Customer Details:</label>
                <textarea
                  class="form-control"
                  id="message-text"
                  rows="6"
                  v-model="editedItem.details"
                ></textarea>
              </div>
              <div class="col-6 mt-3">
                <form class="row align-items-center g-2">
                  <div class="col-auto">
                    <h6 class="text-700 mb-0">From:</h6>
                  </div>
                  <div class="col-md-auto position-relativ">
                    <flat-pickr
                      v-model="editedItem.from"
                      id="CRMDateRange"
                      class="form-control form-control-sm datetimepicker"
                      style="max-width: 130px"
                      placeholder="Select Start"
                    />
                  </div>
                </form>
              </div>
              <div class="col-6 mt-3">
                <form class="row align-items-center g-2">
                  <div class="col-auto">
                    <h6 class="text-700 mb-0">To:</h6>
                  </div>
                  <div class="col-md-auto position-relative">
                    <flat-pickr
                      v-model="editedItem.to"
                      id="CRMDateRange"
                      class="form-control form-control-sm datetimepicker ps-4"
                      style="max-width: 130px"
                      placeholder="Select Start"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-secondary" type="button" data-bs-dismiss="modal">
              Close
            </button>
            <button class="btn btn-primary" type="button" @click="checkout">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import mixins from "../mixins/index";
import ReservationService from "../services/reservation.service";
import PrintService from "../services/print.service";
import AppBar from "@/components/AppBar.vue";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import NavDrawer from "@/components/NavDrawer.vue";
import FooterComp from "@/components/FooterComp.vue";
import VueHtml2pdf from "vue3-html2pdf";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import ModuleService from "../services/module.service";
import LoaderOverlay from "@/components/LoaderOverlay.vue";
import { useVuelidate } from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import EasyDataTable from "vue3-easy-data-table";

import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

const now = new Date();

export default {
  mixins: [mixins],
  name: "RoomsView",
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      formData: {
        title: { required },
        link: { required },
      },
    };
  },
  components: {
    NavDrawer,
    AppBar,
    Swal,
    flatPickr,
    FooterComp,
    VueHtml2pdf,
    LoaderOverlay,
    EasyDataTable,
  },
  data() {
    return {
      searchValue: "",
      table_keys: 1,
      formData: {
        is_root: 0,
        active: 0,
        show_on_main_page: 0,
      },
      items: [],
      availableRooms: [],
      orders_table_key: 0,
      searchField: ["details"],
      headers: [
        { text: "Room", value: "room", sortable: true },
        {
          text: "Details",
          value: "details",
          sortable: true,
          width: 300,
        },
        { text: "From", value: "from", sortable: true },
        { text: "To", value: "to", sortable: true },
        { text: "Served by", value: "served_by", sortable: true },
        { text: "Action", value: "action" },
      ],
      editedItem: {
        room_id: "",
        details: "",
        from: "",
        to: "",
      },
    };
  },
  created() {
    this.getAllReservations();
  },
  computed: {
    loading() {
      return this.$store.getters.getLoading;
    },
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("user"));
  },
  methods: {
    goToReserved() {
      this.$router.push("/reserved");
    },
    goToReservations() {
      this.$router.push("/reservation");
    },
    goToReservationReport() {
      this.$router.push("/reservation-report");
    },
    saveReservation() {
      this.$store.commit("SET_LOADING", true);
      console.log(this.editedItem);
      ReservationService.store(this.editedItem).then(
        (response) => {
          if (response.data.status == "success") {
            PrintService.printReceiptRoom(
              response.data.data.printer_url,
              response.data.data
            ).then(
              (resP) => {
                this.reserve_dialog = false;
                console.log(resP);
              },
              (errorP) => {
                console.log(errorP);
              }
            );
            this.items.push(this.editedItem);
            toast.success("Reservation created successfully!");
            this.$store.commit("SET_LOADING", false);
            this.getAllReservations();
          } else {
            this.$store.dispatch("alert/error", response.data.message);
            this.$store.commit("SET_LOADING", false);
          }
        },
        (error) => {
          if (error.response.code == 422) {
            toast.error(error.response.data.message);
          }
          if (error.response.status == 401) {
            this.handleLogout();
          }
          console.log(error);
          toast.error(error.response.data.message);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    checkout(item) {
      // samdoh
      Swal.fire({
        title: "Confirm",
        text: "Are you sure you want to perform this action?",
        // icon: "warning",
        showDenyButton: true,
        confirmButtonText: "Cool",
        denyButtonText: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.commit("SET_LOADING", true);
          ReservationService.complete(item).then(
            (response) => {
              if (response.data.status == "success") {
                PrintService.printReceiptRoom(
                  response.data.data.printer_url,
                  response.data.data
                ).then(
                  (resP) => {
                    this.reserve_dialog = false;
                    console.log(resP);
                  },
                  (errorP) => {
                    console.log(errorP);
                  }
                );
                this.items.push(this.editedItem);
                toast.success("Reservation closed successfully!");
                this.$store.commit("SET_LOADING", false);
                this.getAllReservations();
              } else {
                this.$store.dispatch("alert/error", response.data.message);
                this.$store.commit("SET_LOADING", false);
              }
            },
            (error) => {
              if (error.response.code == 422) {
                toast.error(error.response.data.message);
              }
              if (error.response.status == 401) {
                this.handleLogout();
              }
              console.log(error);
              toast.error(error.response.data.message);
              this.$store.commit("SET_LOADING", false);
            }
          );
        } else if (result.isDenied) {
          Swal.fire("Changes are not saved", "", "info");
        }
      });
    },
    toogleEnable(item) {
      this.$store.commit("SET_LOADING", true);
      return ReservationService.toggleEnable(item).then(
        (response) => {
          if (response.data.code == 200) {
            this.getAllReservations();
            toast.success("status updated!");
          } else {
            console.log(response.data.error);
            toast.error(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          toast.error(error);
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },

    getAvailableRooms() {
      return ReservationService.getAvailableRooms().then(
        (response) => {
          console.log(response.data.data);
          if (response.data.code == 200) {
            this.availableRooms = response.data.data;
            console.group("availableRooms");
            console.log("availableRooms", this.availableRooms);
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          this.items = [];
          console.log(error);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getAllReservations() {
      this.$store.commit("SET_LOADING", true);
      return ReservationService.getReserved().then(
        (response) => {
          if (response.data.status == "success") {
            this.items = response.data.data;
          } else {
            this.items = [];
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          this.items = [];
          console.log(error);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    toggleShowOnMain() {
      this.formData.show_on_main_page = !this.formData.show_on_main_page;
    },
    toggleActive() {
      this.formData.active = !this.formData.active;
    },
    toggleRoot() {
      this.formData.is_root = !this.formData.is_root;
    },
    saveResource() {
      this.v$.formData.title.$touch();
      this.v$.formData.link.$touch();
      if (this.v$.formData.$invalid) {
        return;
      }
      this.$store.commit("SET_LOADING", true);
      ModuleService.store(this.formData).then(
        (response) => {
          console.log(response);
          if (response.data.code == 200) {
            this.table_keys += 1;
            toast.success(response.data.message, {
              autoClose: 2000,
            });
            this.getData();
          } else {
            console.log(response.data.error);
            toast.error(response.data.error, {
              autoClose: 2000,
            });
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    updateResource(resource) {
      this.$store.commit("SET_LOADING", true);
      this.formData.id = resource.id;
      if (!this.formData.icon) {
        this.formData.icon = resource.icon;
      }
      if (!this.formData.title) {
        this.formData.title = resource.title;
      }
      if (!this.formData.color) {
        this.formData.color = resource.color;
      }
      if (!this.formData.detail) {
        this.formData.detail = resource.detail;
      }
      if (!this.formData.order) {
        this.formData.order = resource.order;
      }
      if (!this.formData.link) {
        this.formData.link = resource.link;
      }
      if (!this.formData.id_parent) {
        this.formData.id_parent = resource.id_parent;
      }
      if (!this.formData.is_root) {
        this.formData.is_root = resource.is_root;
      }
      if (!this.formData.active) {
        this.formData.active = resource.active;
      }
      if (!this.formData.show_on_main_page) {
        this.formData.show_on_main_page = resource.show_on_main_page;
      }
      ModuleService.update(this.formData).then(
        (response) => {
          console.log(response);
          if (response.data.code == 200) {
            toast.success(response.data.message, {
              autoClose: 2000,
            });
            this.getData();
            // Clear modals
            $("#edit-modal" + resource.id)
              .removeClass("fade")
              .hide();
            $(".modal-backdrop").remove();
          } else {
            this.resources = [];
            console.log(response.data.error);
            toast.error(response.data.error, {
              autoClose: 2000,
            });
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    deleteRecource(resource) {
      this.$store.commit("SET_LOADING", true);
      let sure = confirm("Are you sure you want to delete? you cannot undo this action.");
      if (sure) {
        ModuleService.delete(resource.id).then(
          (response) => {
            console.log(response);
            if (response.data.code == 200) {
              toast.success(response.data.message, {
                autoClose: 2000,
              });
              this.getData();
              $("#edit-modal" + resource.id)
                .removeClass("fade")
                .hide();
              $(".modal-backdrop").remove();
            } else {
              console.log(response.data.error);
              toast.error(response.data.error, {
                autoClose: 2000,
              });
            }
            this.$store.commit("SET_LOADING", false);
          },
          (error) => {
            console.log(error);
            this.$store.commit("SET_LOADING", false);
          }
        );
      }
    },
    getData() {
      this.$store.commit("SET_LOADING", true);
      return ModuleService.index().then(
        (response) => {
          if (response.data.code == 200) {
            this.resources = response.data.data;
          } else {
            this.resources = [];
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
  },
};
</script>
